.app.dark {
  background-color: #111;
  color: rgb(156, 156, 156);

  .chartGrid {
    stroke: rgba(228, 228, 228, 0.219);
  }

  .table {
    background-color: #111;

    .tableCell {
      color: gray;
    }
  }
  .input{
    color: #FF9100;
  }
  
  .select-menu{
    padding-bottom: 50pt;
    padding-top: 10pt;
  }
  
  .save-btn{
    background-color: darkorange;
    color: #fff;
    
  }
  
  .select-input{
    padding-bottom: 50pt;
    
  }
  
  #date{
    color: #fff;
  }
  
  #duration{
    color:#fff;
  }
  
  #start-time{
    color:#fff;
  }
  
  #filename{
    color:#fff;
  }

  .navbar {
    color: #999;
    border-color: #333;

    .search {
      border-color: gray;
    }
  }

  .sidebar {
    background-color: #111;
    border-color: #333;

    .top {
      .logo {
        color: #FF9100;
      }
    }
    hr {
      border-color: #333;
    }

    ul {
      li {
        &:hover {
          background-color: #333;
        }

        .icon {
          color: #999;
        }
      }
    }
  }

  .datatable {
    .datagrid {
      color: gray;
      border: none;

      .viewButton,
      .deleteButton,
      .cellWithStatus {
        color: gray;
        border: none;
      }
    }
  }

  input{
      background-color: transparent;
  }
}
